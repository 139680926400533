import React, { useState, useEffect } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import NavBar from './NavBar';
import ASide from './ASide';
import Footer from './Footer';
import { useAuth } from './AuthContext';
import Snackbar from '@mui/material/Snackbar';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/system';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Autocomplete from "@mui/material/Autocomplete";
import { publish } from "../EventsAI";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CommentIcon from '@mui/icons-material/Comment';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import CreateTwoToneIcon from '@mui/icons-material/CreateTwoTone';
import ListItemIcon from '@mui/material/ListItemIcon';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import SearchIcon from '@mui/icons-material/Search';
import PanIcon from '@mui/icons-material/PanTool';
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';
import { v4 as uuidv4 } from 'uuid';
import { useDebouncedCallback } from 'use-debounce';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function CreateContext() {
  const [contextFile, setContextFile] = useState({});
  const [topics, setTopics] = useState([]);
  const { checkAuthenticated, getToken } = useAuth();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [search, setSearch] = useState(false);
  const [type, setType] = useState("");
  const [panViewOpen, setPanViewOpen] = useState(false);
  const [editChild, setEditChild] = useState(false);
  const [currentEdit, setCurrentEdit] = useState({});
  const [currentEditChild, setCurrentEditChild] = useState({});
  const [editBuffer, setEditBuffer] = useState({});
  const [editBufferChild, setEditBufferChild] = useState({});
  const [recordId, setRecordId] = useState(-1);
  const [titles, setTitles] = useState({
    title: "Nuevo Temario",
    button: "Crear Temario"
  });
  const [fromManual, setFromManual] = useState(false);
  const [currentParent, setCurrentParent] = useState(0);
  const [currentParentUuid, setCurrentParentUuid] = useState("");
  const [topic, setTopic] = useState("");
  const [query, setQuery] = useState("");
  const [queryResults, setQueryResults] = useState([]);
  const [deleteds, setDeleteds] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (response) => {
    setOpen(false);

    if(response){
      window.location.replace(process.env.REACT_APP_URL + "/dashboard")
    }
  };

  const handleEdit = () => {
    setEdit(false);
  };

  const handleEditChild = () => {
    setEditChild(false);
  };

  const handleSearch = () => {
    setSearch(false);
    clearSearch();
  };

  const [formValues, setFormValues] = useState({
    name:{
      value:'',
      error:false,
      errorMessage:'El campo es obligatorio'
    },
    description:{
      value:'',
      error:false,
      errorMessage:''
    },
    public:{
      value:false,
      error:false,
      errorMessage:''
    },
  });

  const [content, setContent] = useState([]);
  const [contentBuffer, setContentBuffer] = useState([]);

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const handleFileChange = (e) => {
    setContextFile(e.target.files);
  }

  const handleFormChange = (e) => {
    const {name, value} = e.target;

    let newValues = JSON.parse(JSON.stringify(formValues));
    newValues[name].value = value;
    setFormValues(newValues);
    console.log(newValues);
  }

  const handlePublicChange = (e) => {
    const {name, value} = e.target;

    let newValues = JSON.parse(JSON.stringify(formValues));
    newValues["public"].value = e.target.checked;
    setFormValues(newValues);
  }

  const handleManualChange = (e) => {
    setFromManual(e.target.checked);
  }

  const handleTopicChange = (e) => {
    console.log(e.target.value)
    setTopic(e.target.value);
    handleQueryChange(query);
  }

  const copyContent = (oldContent) => {
    let tmp = [];
    for(let key in oldContent){
      tmp.push(Object.assign({}, oldContent[key]));
    }
    return tmp;
  }

  const handleQueryChange = useDebouncedCallback(
    // function
    (value) => {
      console.log(value);
      setQuery(value)
      handleQueryChangeDouble();
    },
    // delay in ms
    250
  );

  const handleQueryChangeDouble = useDebouncedCallback(
    // function
    () => {
      fetchSearch();
    },
    // delay in ms
    100
  );

  const clearSearch = (e) => {
    setTopic("")
    setQuery("")
    setQueryResults([])
  }

  async function fetchSearch() {
    publish("loading", true);

    try{
      const response = await fetch(process.env.REACT_APP_API_HOST + "/topics/search", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
        body: JSON.stringify({
          query: query || "",
          topic: topic + "" || "",
          type: type || ""
        })
      });
      const data = await response.json();

      if(data && data.message && data.message == "success"){
        setQueryResults(data.data || []);
      }else{
        publish("error");
      }
    }catch(e){
      publish("error",e.message);
      console.log(e);
    }

    publish("loading", false);
  }

  async function fetchContexts() {
    publish("loading", true);

    try{
      const response = await fetch(process.env.REACT_APP_API_HOST + "/topics/list", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
        body: JSON.stringify({})
      });
      const data = await response.json();

      if(data && data.message && data.message == "success"){
        setTopics(data.data || []);
      }else{
        publish("error");
      }
    }catch(e){
      publish("error",e.message);
      console.log(e);
    }

    publish("loading", false);
  }

  async function fetchContext(id) {
    setRecordId(id);

    publish("loading", true);

    try{
      const response = await fetch(process.env.REACT_APP_API_HOST + "/topics/" + id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        }
      });
      const data = await response.json();

      if(data && data.message && data.message == "success"){
        try{
          let newValues = JSON.parse(JSON.stringify(formValues));
          let editProps = ["name","description","public"];
          editProps.forEach((prop) => {
            let finalValue = data.data[prop];
            if(prop == "public") finalValue = finalValue ? true : false;
            newValues[prop.replace("_","")].value = finalValue;
          });
          setFormValues(newValues);
          data.topicdata.forEach((x) => delete x["ID"])
          data.topicdata.forEach((x) => {
            if(!x["uuid"]){
              x["uuid"] = uuidv4();
            }
          })
          setContent(data.topicdata);
          setContentBuffer(copyContent(data.topicdata));
        }catch(e){
          console.log(e);
        }
      }else{
        publish("error");
      }
    }catch(e){
      publish("error",e.message);
      console.log(e);
    }

    publish("loading", false);
  }

  useEffect(() => {
    checkAuthenticated();

    const searchParams = new URLSearchParams(window.location.search);
    if(searchParams && searchParams.has('id') && searchParams.get('id')){
      fetchContext(searchParams.get('id'));
      setTitles({
        title: "Editar Temario",
        button: "Editar Temario"
      });
    }

    fetchContexts();
  }, []);

  const handleSubmit = async (event) => {
    publish("loading", true);

    try{
      event.preventDefault();

      let errors = false;
      const formFields = Object.keys(formValues);
      let newFormValues = {...formValues}

      for (let index = 0; index < formFields.length; index++) {
        const currentField = formFields[index];
        const currentValue = formValues[currentField].value;

        if(formFields[index] == 'name'){
          if(currentValue === ''){
            errors = true;

            newFormValues = {
              ...newFormValues,
              [currentField]:{
                ...newFormValues[currentField],
                error:true
              }
            }

          }
        }
      }

      setFormValues(newFormValues);
      if(errors){
        publish("loading", false);
        return;
      }
      
      const form = new FormData(event.currentTarget);
      form.append("file",contextFile[0]);
      form.append("public",formValues.public.value);
      form.append("content",JSON.stringify(content));
      if(deleteds.length > 0) form.append("deleted",deleteds.join(","));
      if(recordId) form.append("id",recordId);

      const response = await fetch(process.env.REACT_APP_API_HOST + "/topics", {
        method: 'POST',
        headers: {
          'Authorization': getToken(),
        },
        body: form
      });
      const data = await response.json();

      if(data && data.message && data.message == "success"){
        publish("success");
        //setOpen(true);
        handleClose(true);
      }else{
        publish("error");
      }
    }catch(e){
      publish("error",e.message);
      console.log(e);
    }

    publish("loading", false);
  };

  return (
    <div>
      <NavBar title={titles.title} />
      <Box sx={{ display: 'flex', flexDirection: {xs:'column', md: 'row'} }}>
        <ASide />
        <Box sx={{ width: {xs: panViewOpen ? '65%' : '80%', md: panViewOpen ? '85%' : '100%'} }}>
          <Container component="main">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Nombre"
                  name="name"
                  value={formValues.name.value}
                  error={formValues.name.error}
                  helperText={formValues.name.error && formValues.name.errorMessage}
                  onChange={handleFormChange}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  id="description"
                  label="Descripción"
                  name="description"
                  value={formValues.description.value}
                  error={formValues.description.error}
                  helperText={formValues.description.error && formValues.description.errorMessage}
                  onChange={handleFormChange}
                />
                <FormControl fullWidth>
                  <InputLabel id="public_label">Público</InputLabel>
                  <Checkbox
                    margin="normal"
                    onChange={handlePublicChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={formValues.public.value}
                  />
                </FormControl>
                {!fromManual ? 
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Subir Contexto (.PDF)
                    <VisuallyHiddenInput type="file" onChange={handleFileChange} />
                  </Button>
                :
                  (null)
                }
                <div style={{margin:"2% 0 2% 0"}}></div>
                <FormControl fullWidth>
                  <InputLabel id="manual_label">Editar contenido manualmente</InputLabel>
                  <Checkbox
                    margin="normal"
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={fromManual}
                    onChange={handleManualChange}
                  />
                </FormControl>
                {fromManual ? 
                  <Box>
                    <div style={{margin:"2% 0 2% 0"}}></div>
                    <Divider />
                    <Typography variant="h4" component="h4">
                      Partes <AddTwoToneIcon onClick={function(e){
                        e.preventDefault();
                        content.push({
                            "topic_id": content.length > 0 ? content[0].topic_id : -1,
                            "title": "",
                            "text": "",
                            "type": "Parte",
                            "order": content.length > 0 ? (content[content.length-1].order || 0) + 1 : 1,
                            "uuid": uuidv4()
                        });
                        setContent(copyContent(content))
                        setContentBuffer(copyContent(content));
                      }} />
                      <SearchIcon onClick={function(e){
                        e.preventDefault();
                        setPanViewOpen(false);
                        setSearch(true);
                        setType("Parte")
                      }} />
                      <PanIcon onClick={function(e){
                        e.preventDefault();
                        setSearch(false);
                        setPanViewOpen(true);
                        setType("Parte")
                      }} />
                    </Typography>
                    <Divider />
                    <div style={{margin:"2% 0 2% 0"}}></div>
                    <List>
                      {contentBuffer.filter((x) => x.type == "Parte" && !x.parent).map((value) => (
                        <ListItem
                          key={value.uuid}
                          disableGutters
                        >
                          <ListItemText>
                            {parse((value.title || "") + "")}
                          </ListItemText>
                          <ListItemIcon>
                            <CreateTwoToneIcon onClick={function(e){
                              e.preventDefault();
                              setCurrentEdit(this.item);
                              setCurrentParent(this.item.id);
                              setCurrentParentUuid(this.item.uuid);
                              setEdit(true);
                            }.bind({item: value})}/>
                          </ListItemIcon>
                          <ListItemIcon>
                            <DeleteTwoToneIcon onClick={function(e){
                              e.preventDefault();
                              setContent(content.filter(x1 => value.id ? x1.id != value.id : (value.uuid ? x1.uuid != value.uuid : true)))
                              setContentBuffer(copyContent(content));
                              if(value.id) setDeleteds(deleteds.concat(value.id))
                            }} />
                          </ListItemIcon>
                        </ListItem>
                      ))}
                    </List>
                    <div style={{margin:"2% 0 2% 0"}}></div>
                    <Divider />
                    <Typography variant="h4" component="h4">
                      Fichas <AddTwoToneIcon onClick={function(e){
                        e.preventDefault();
                        content.push({
                            "topic_id": content.length > 0 ? content[0].topic_id : -1,
                            "title": "",
                            "text": "",
                            "type": "Ficha",
                            "order": content.length > 0 ? (content[content.length-1].order || 0) + 1 : 1,
                            "uuid": uuidv4()
                        });
                        setContent(copyContent(content))
                        setContentBuffer(copyContent(content));
                      }} />
                      <SearchIcon onClick={function(e){
                        e.preventDefault();
                        setPanViewOpen(false);
                        setSearch(true);
                        setType("Ficha");
                      }} />
                      <PanIcon onClick={function(e){
                        e.preventDefault();
                        setSearch(false);
                        setPanViewOpen(true);
                        setType("Ficha")
                      }} />
                    </Typography>
                    <Divider />
                    <div style={{margin:"2% 0 2% 0"}}></div>
                    <List>
                      {contentBuffer.filter((x) => x.type == "Ficha" && !x.parent).map((value) => (
                        <ListItem
                          key={value.uuid}
                          disableGutters
                        >
                          <ListItemText>
                            {parse((value.title || "") + "")}
                          </ListItemText>
                          <ListItemIcon>
                            <CreateTwoToneIcon onClick={function(e){
                              e.preventDefault();
                              setCurrentEdit(this.item);
                              setCurrentParent(this.item.id);
                              setCurrentParentUuid(this.item.uuid);
                              setEdit(true);
                            }.bind({item: value})}/>
                          </ListItemIcon>
                          <ListItemIcon>
                            <DeleteTwoToneIcon onClick={function(e){
                                e.preventDefault();
                                setContent(content.filter(x1 => value.id ? x1.id != value.id : (value.uuid ? x1.uuid != value.uuid : true)))
                                setContentBuffer(copyContent(content));
                                if(value.id) setDeleteds(deleteds.concat(value.id))
                              }} />
                          </ListItemIcon>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                :
                  (null)
                }
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {titles.button}
                </Button>
                {/*<Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="#" variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>*/}
              </Box>
            </Box>
          </Container>
        </Box>
        {
          panViewOpen 
          ?
            <Box sx={{ width: {xs:'15%', md: '15%'} }}>
              <Select
                label="Temario"
                onChange={handleTopicChange}
              >
                <MenuItem value="">(Todos)</MenuItem>
                {topics.map((x) => 
                  <MenuItem value={x.id}>{x.name}</MenuItem>
                )}
              </Select>
              <TextField
                margin="normal"
                fullWidth
                id="query"
                label="Búsqueda libre"
                name="query"
                onChange={(e) => handleQueryChange(e.target.value)}
              />
              <List>
                {queryResults.map((value) => (
                  <ListItem
                    key={value.uuid}
                    disableGutters
                    onClick={async (e) => {
                      let error = false;
                      let childsData = [];

                      publish("loading", true);

                      try{
                        const response = await fetch(process.env.REACT_APP_API_HOST + "/topics/search", {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                            'Authorization': getToken(),
                          },
                          body: JSON.stringify({
                            topic: value.topic_id + "",
                            type: value.type == "Parte" ? "ParteSection" : "FichaSection",
                            parent: value.id + ""
                          })
                        });
                        const childs = await response.json();

                        if(childs && childs.message && childs.message == "success"){
                          childsData = childs.data || [];
                        }else{
                          publish("error");
                          error = true;
                        }
                      }catch(e){
                        publish("error",e.message);
                        console.log(e);
                        error = true;
                      }

                      publish("loading", false);

                      if(!error){
                        let currentOrder = content.length > 0 ? (content[content.length-1].order || 0) : 0;
                        currentOrder++;

                        if((!value.parent && !value.parentUuid)){
                          let tmpParent = {
                              "topic_id": value.topic_id,
                              "parent": value.parent,
                              "title": value.title,
                              "text": value.text,
                              "type": value.type,
                              "order": currentOrder,
                              "uuid": uuidv4()
                          };

                          content.push(tmpParent);

                          for(let key in childsData){
                            currentOrder++;

                            let tmpChild = {
                                "topic_id": childsData[key].topic_id,
                                "parentUuid": tmpParent.uuid,
                                "title": childsData[key].title,
                                "text": childsData[key].text,
                                "type": childsData[key].type,
                                "order": currentOrder,
                                "uuid": uuidv4()
                            };

                            delete tmpChild.id;
                            delete tmpChild.parent;
                            tmpChild.order = currentOrder;
                            tmpChild.uuid = uuidv4();
                            tmpChild.parentUuid = tmpParent.uuid;
                            content.push(tmpChild);
                          }

                          setContent(copyContent(content))
                          setContentBuffer(copyContent(content));
                        }else{
                          let tmpParent = {
                              "topic_id": currentEdit.topic_id,
                              "parent": currentEdit.id,
                              "parentUuid": currentEdit.uuid,
                              "title": value.title,
                              "text": value.text,
                              "type": value.type,
                              "order": currentOrder,
                              "uuid": uuidv4()
                          };

                          contentBuffer.push(tmpParent);
                          setContentBuffer(copyContent(contentBuffer));
                          setEdit(false);
                          setEdit(true);
                        }
                      }

                      handleSearch()
                    }}
                  >
                    <ListItemText>
                      {
                        value.name ?
                        <Typography>
                          Temario: {value.name}
                        </Typography>
                        :
                        (null)
                      }
                      {
                        value.parent_name ?
                        <Typography>
                          Sección: {value.parent_name}
                        </Typography>
                        :
                        (null)
                      }
                      <Typography variant="h6" component="h6">
                        {parse((value.title || "") + "")}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Box>
          :
          (null)
        }
      </Box>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Opciones
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Acción correcta, ¿que deseas hacer ahora?.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(true)}>Ir al listado</Button>
          <Button autoFocus onClick={() => handleClose(false)}>
            Crear más entradas
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen
        open={edit}
        onClose={() => handleEdit()}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Edición
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          <JoditEditor
            value={currentEdit.title}
            onChange={(htmlString) => {
              setEditBuffer({title: htmlString})
            }}
          />
          <div style={{margin:"2% 0 2% 0"}}></div>
          <Divider />
          <Typography variant="h4" component="h4">
            Secciones <AddTwoToneIcon onClick={function(e){
              e.preventDefault();
              contentBuffer.push({
                  "topic_id": contentBuffer[0].topic_id,
                  "parent": currentParent,
                  "parentUuid": currentParentUuid,
                  "title": "",
                  "text": "",
                  "type": currentEdit.type == "Parte" ? "ParteSection" : "FichaSection",
                  "order": contentBuffer.length > 0 ? (contentBuffer[contentBuffer.length-1].order || 0) + 1 : 1,
                  "uuid": uuidv4()
              });
              setContentBuffer(copyContent(contentBuffer));
              setEdit(false);
              setEdit(true);
            }} />
            <SearchIcon onClick={function(e){
              e.preventDefault();
              setSearch(true)
              setType(currentEdit.type == "Parte" ? "ParteSection" : "FichaSection")
            }} />
          </Typography>
          <Divider />
          <div style={{margin:"2% 0 2% 0"}}></div>
          <List>
            {contentBuffer.filter((x) => ((currentParent && x.parent == currentParent) || (currentParentUuid && x.parentUuid == currentParentUuid))).map((value) => (
              <ListItem
                key={value.uuid}
                disableGutters
              >
                <ListItemText>
                  {parse((value.title || "") + "")}
                </ListItemText>
                <ListItemIcon>
                  <CreateTwoToneIcon onClick={function(e){
                    e.preventDefault();
                    setCurrentEditChild(this.item);
                    setEditChild(true);
                  }.bind({item: value})}/>
                </ListItemIcon>
                <ListItemIcon>
                  <DeleteTwoToneIcon onClick={function(e){
                    e.preventDefault();
                    setContentBuffer(contentBuffer.filter(x1 => value.id ? x1.id != value.id : (value.uuid ? x1.uuid != value.uuid : true)))
                    if(value.id) setDeleteds(deleteds.concat(value.id))
                    setEdit(false);
                    setEdit(true);
                  }} />
                </ListItemIcon>
              </ListItem>
            ))}
          </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            currentEdit.title = editBuffer.title || currentEdit.title
            for(let key in contentBuffer){
              if(((contentBuffer[key].id && currentEdit.id) && (contentBuffer[key].id == currentEdit.id)) || ((contentBuffer[key].uuid && currentEdit.uuid) && (contentBuffer[key].uuid == currentEdit.uuid))){
                contentBuffer[key].title = editBuffer.title || contentBuffer[key].title
              }
            }
            setContent(copyContent(contentBuffer));
            handleEdit();
          }}>Guardar</Button>
          <Button autoFocus onClick={() => {
            setContentBuffer(copyContent(content));
            handleEdit()
          }}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen
        open={editChild}
        onClose={() => handleEditChild()}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Edición
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          <JoditEditor
            value={currentEditChild.title}
            onChange={(htmlString) => {
              let newBuffer = Object.assign({}, editBufferChild);
              newBuffer.title = htmlString;
              setEditBufferChild(newBuffer)
            }}
          />
          <JoditEditor
            value={currentEditChild.text}
            onChange={(htmlString) => {
              let newBuffer = Object.assign({}, editBufferChild);
              newBuffer.text = htmlString;
              setEditBufferChild(newBuffer)
            }}
          />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            currentEditChild.title = editBufferChild.title || currentEditChild.title
            currentEditChild.text = editBufferChild.text || currentEditChild.text
            handleEditChild()
          }}>Guardar</Button>
          <Button autoFocus onClick={() => {
            handleEditChild()
          }}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={search}
        onClose={() => handleSearch()}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Búsqueda
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          <Select
            label="Temario"
            onChange={handleTopicChange}
          >
            <MenuItem value="">(Todos)</MenuItem>
            {topics.map((x) => 
              <MenuItem value={x.id}>{x.name}</MenuItem>
            )}
          </Select>
          <TextField
            margin="normal"
            fullWidth
            id="query"
            label="Búsqueda libre"
            name="query"
            onChange={(e) => handleQueryChange(e.target.value)}
          />
          <List>
            {queryResults.map((value) => (
              <ListItem
                key={value.uuid}
                disableGutters
                onClick={async () => {
                  let error = false;
                  let childsData = [];

                  publish("loading", true);

                  try{
                    const response = await fetch(process.env.REACT_APP_API_HOST + "/topics/search", {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        'Authorization': getToken(),
                      },
                      body: JSON.stringify({
                        topic: value.topic_id + "",
                        type: value.type == "Parte" ? "ParteSection" : "FichaSection",
                        parent: value.id + ""
                      })
                    });
                    const childs = await response.json();

                    if(childs && childs.message && childs.message == "success"){
                      childsData = childs.data || [];
                    }else{
                      publish("error");
                      error = true;
                    }
                  }catch(e){
                    publish("error",e.message);
                    console.log(e);
                    error = true;
                  }

                  publish("loading", false);

                  if(!error){
                    let currentOrder = content.length > 0 ? (content[content.length-1].order || 0) : 0;
                    currentOrder++;

                    if((!value.parent && !value.parentUuid)){
                      let tmpParent = {
                          "topic_id": value.topic_id,
                          "parent": value.parent,
                          "title": value.title,
                          "text": value.text,
                          "type": value.type,
                          "order": currentOrder,
                          "uuid": uuidv4()
                      };

                      content.push(tmpParent);

                      for(let key in childsData){
                        currentOrder++;

                        let tmpChild = {
                            "topic_id": childsData[key].topic_id,
                            "parentUuid": tmpParent.uuid,
                            "title": childsData[key].title,
                            "text": childsData[key].text,
                            "type": childsData[key].type,
                            "order": currentOrder,
                            "uuid": uuidv4()
                        };

                        delete tmpChild.id;
                        delete tmpChild.parent;
                        tmpChild.order = currentOrder;
                        tmpChild.uuid = uuidv4();
                        tmpChild.parentUuid = tmpParent.uuid;
                        content.push(tmpChild);
                      }

                      setContent(copyContent(content))
                      setContentBuffer(copyContent(content));
                    }else{
                      let tmpParent = {
                          "topic_id": currentEdit.topic_id,
                          "parent": currentEdit.id,
                          "parentUuid": currentEdit.uuid,
                          "title": value.title,
                          "text": value.text,
                          "type": value.type,
                          "order": currentOrder,
                          "uuid": uuidv4()
                      };

                      contentBuffer.push(tmpParent);
                      setContentBuffer(copyContent(contentBuffer));
                      setEdit(false);
                      setEdit(true);
                    }
                  }

                  handleSearch()
                }}
              >
                <ListItemText>
                  {
                    value.name ?
                    <Typography>
                      Temario: {value.name}
                    </Typography>
                    :
                    (null)
                  }
                  {
                    value.parent_name ?
                    <Typography>
                      Sección: {value.parent_name}
                    </Typography>
                    :
                    (null)
                  }
                  <Typography variant="h6" component="h6">
                    {parse((value.title || "") + "")}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => {
            handleSearch()
          }}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </div>
  );
}